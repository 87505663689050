import React from "react"

const NZFinancialServicesRegulatoryFramework = () => {
  const styles = {
    heading: {
      marginBottom: "10px",
    },
    button: {
      cursor: "pointer",
      color: "white",
      textAlign: "center",
      fontWeight: 700,
      backgroundColor: "#092c4c",
      fontSize: "18px",
      padding: "15px 35px",
      borderRadius: "30px",
      display: "block",
      marginTop: "20px",
    },
    div: {
      marginBottom: "35px",
    },
  }

  return (
    <div>
      <h6 style={styles.heading}>Overview</h6>
      <div style={styles.div}>
        Under the Code of Professional Conduct for Financial Advice Services
        (Code of Conduct) you also need to demonstrate knowledge and
        competencies in relation to the New Zealand Financial Services
        Regulatory Framework that were not covered in earlier versions of the
        level 5 qualification.
      </div>
      <div style={styles.div}>
        One of the easiest ways to demonstrate these competencies is to complete
        our bridging course. Our course was designed for advisers who have
        already completed the New Zealand Certificate in Financial Services
        version 1, completed the National Certificate in Financial Services or
        other financial advisers or nominated representatives who may not have
        completed any of the minimum qualification options. The course is all
        online, with multi choice questions allowing you to complete your
        learning at your own pace (including from a mobile device). After
        enrolment you have three months of access to the course in order to
        complete it – more than enough time for practicing advisers, with
        extensions available.
      </div>
      <div style={styles.div}>
        The bridging course will provide you with knowledge to be up to date
        with the current financial services regulatory framework, including the
        amendments to the Financial Markets Conduct Act 2013, introduction of
        the new Code of Conduct and current disclosure requirements.
      </div>
      <div>
        Enrol now for our end of the financial year offer and update yourself on
        the current regulatory framework before the end of the transition
        period.
      </div>
      <div>
        <span style={{ textDecoration: "line-through", marginRight: "5px" }}>
          $250
        </span>
        $225 plus GST
      </div>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform"
        target="_blank"
      >
        <button style={styles.button}>Enrol now</button>
      </a>
    </div>
  )
}

export default NZFinancialServicesRegulatoryFramework
